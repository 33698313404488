// NativeVideoPlayer.js
'use strict';
import { connect } from 'react-redux';

import NativeVideoPlayer from '../component/NativeVideoPlayer.jsx';

import getMeData from '../selector/getMeData.js';
import getMessageData from '../selector/getMessageData.js';

const mapStateToProps = (state, { messageId }) => {
  return {
    meId: getMeData(state, 'id'),
    senderId: getMessageData(state, messageId, 'senderId'),
  };
};

export default connect(mapStateToProps, null)(NativeVideoPlayer);
