// getFlixBadgeColor.js
'use strict';
import createCachedSelector from '../resource/createCachedSelector.js';

import { MESSAGE_BADGE_NONE } from '../RemoteConfigKeys.js';

/**
 * Select flix badge color by badge name
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} badgeName - badge name.
 * @return {string} The selected flix badge color.
 */
const getFlixBadgeColor = createCachedSelector(
  state => state.get('remoteConfig'),
  (state, badgeName) => badgeName,
  (remoteConfig, badgeName) => {
    const flixBadgesKey = remoteConfig?.filter((value, key) => {
      return /^MESSAGE_BADGE_/.test(key) && key !== MESSAGE_BADGE_NONE;
    });

    return flixBadgesKey.reduce((result, value, key) => {
      if (result) return result;
      const color = key.replace('MESSAGE_BADGE_', '');
      const badgeColorList = remoteConfig.get(key)?.split(' ') || [];

      return badgeColorList?.includes(badgeName) ? color : result;
    }, '');
  }
)((state, badgeName) => `${badgeName}`);

export default getFlixBadgeColor;
