// FlixBadge.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import { TranslationNamespace } from '../resource/translationNamespace.js';

import { textColor } from '../style/variables.js';

const getActualName = (isOnFreezone, badgeName) => {
  if (isOnFreezone && badgeName === 'hot') {
    return 'badge_video_premium';
  } else {
    return `badge_video_${badgeName}`;
  }
};

export const FlixBadge = ({ t, backgroundColor, badgeName, isOnFreezone }) => {
  const name = getActualName(isOnFreezone, badgeName);
  return (
    <StyledFlixBadge backgroundColor={backgroundColor}>
      {t(`${name}`, {
        ns: TranslationNamespace.FEED,
      })}
    </StyledFlixBadge>
  );
};

FlixBadge.propTypes = {
  backgroundColor: PropTypes.string,
  badgeName: PropTypes.string,
  isOnFreezone: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

FlixBadge.defaultProps = {
  backgroundColor: null,
  badgeName: null,
  isOnFreezone: false,
};

const StyledFlixBadge = styled.div`
  margin-right: 4px;
  border-radius: 10px;
  padding-left: 8px;
  padding-right: 8px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? `#${backgroundColor}` : 'rgba(207, 4, 4, 0.6)'};
  color: ${textColor.white};
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
`;

export default withTranslation()(FlixBadge);
