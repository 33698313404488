// SwagLoading.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components';

import TextS from '../../img/img-loading-s.svg';
import TextW from '../../img/img-loading-w.svg';
import TextA from '../../img/img-loading-a.svg';
import TextG from '../../img/img-loading-g.svg';

class SwagLoading extends React.PureComponent {
  render() {
    const { shouldAnimate } = this.props;
    return (
      <StyledSwagLoading>
        <SIcon src={TextS} alt="S" shouldAnimate={shouldAnimate} />
        <WIcon src={TextW} alt="W" shouldAnimate={shouldAnimate} />
        <AIcon src={TextA} alt="A" shouldAnimate={shouldAnimate} />
        <GIcon src={TextG} alt="G" shouldAnimate={shouldAnimate} />
      </StyledSwagLoading>
    );
  }
}

SwagLoading.propTypes = {
  shouldAnimate: PropTypes.bool,
};

SwagLoading.defaultProps = {
  shouldAnimate: true,
};

const StyledSwagLoading = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  opacity: 0.203;
`;
const TextIcon = styled.img`
  margin-left: -5px;
  width: 25%;
  height: 100%;
  &:first-child {
    margin-left: 7px;
  }
`;

const jump = keyframes`
  0%{
    transform: translateY(0px);
  }
  35%{
    transform: translateY(-10px);
  }
  52%{
    transform: translateY(5px);
  }
  69%{
    transform: translateY(0px);
  }
  100%{
    transform: translateY(0px);
  }
`;

const SIcon = styled(TextIcon)`
  animation: ${({ shouldAnimate }) =>
    shouldAnimate
      ? css`
          ${jump} 1.5s ease-out infinite
        `
      : 'none'};
`;
const WIcon = styled(TextIcon)`
  animation: ${({ shouldAnimate }) =>
    shouldAnimate
      ? css`
          ${jump} 1.5s ease-out 0.15s infinite
        `
      : 'none'};
`;
const AIcon = styled(TextIcon)`
  animation: ${({ shouldAnimate }) =>
    shouldAnimate
      ? css`
          ${jump} 1.5s ease-out 0.3s infinite
        `
      : 'none'};
`;
const GIcon = styled(TextIcon)`
  animation: ${({ shouldAnimate }) =>
    shouldAnimate
      ? css`
          ${jump} 1.5s ease-out 0.45s infinite
        `
      : 'none'};
`;

export default SwagLoading;
