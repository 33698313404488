// archiveConstants.js
'use strict';

export const archiveType = {
  STORY: 'story',
  FLIX: 'flix',
  IMAGE: 'image',
  CHAT: 'chat',
  MESSAGE_PACK: 'messagePacks',
  ALL: 'all',
  SHORT: 'short',
};
