// FlixBadge.js
import { connect } from 'react-redux';
import withRouter from '../component/WithRouter.jsx';
import FlixBadge from '../component/FlixBadge.jsx';
import getFlixBadgeColor from '../selector/getFlixBadgeColor.js';
import getIsMatchedPathname from '../resource/getIsMatchedPathname.js';

const mapStateToProps = (state, { badgeName, location }) => {
  const isOnFreezone = getIsMatchedPathname({
    pathname: location?.pathname,
    dataKey: 'isOnFreezone',
  });
  return {
    isOnFreezone,
    badgeName,
    backgroundColor: getFlixBadgeColor(state, badgeName),
  };
};

export default withRouter(connect(mapStateToProps, null)(FlixBadge));
