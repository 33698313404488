// getPublicSrcSet.js
'use strict';
import { LRUCache } from 'lru-cache';

import { MediaAssetFormat } from '../resource/getMediaAsset.js';

const cache = new LRUCache({ max: 256 });

/**
 * Get src set with url from public.swag.live
 * @param {string} href - original asset url from public.swag.live.
 * @param {number} {[size = 512]} - size in pixel
 * @param {string} {[format = jpg]} - format from MediaAssetFormat in getPostMediaAsset
 */
const getPublicSrcSet = ({
  href,
  size = 512,
  format = MediaAssetFormat.JPG,
}) => {
  const key = `${href}:${size}:${format}`;
  let result = cache.get(key);

  if (!result) {
    try {
      const url = new URL(href);
      url.searchParams.set('size', `${size}x${size}`);
      url.searchParams.set('format', format);
      const oneX = url.href;
      url.searchParams.set('size', `${2 * size}x${2 * size}`);
      const twoX = url.href;
      result = { src: oneX, srcSet: `${oneX} 1x, ${twoX} 2x` };
    } catch (_) {
      result = { src: href, srcSet: href };
    }
    cache.set(key, result);
  }
  return result;
};

export default getPublicSrcSet;
